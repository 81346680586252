
.slick-dots {
    display: flex;
    justify-content: center;
    list-style-type: none;
}
.slick-dots li {
    margin: 0 0.25rem;
}
.slick-dots li button {
    display: block;
    width: 1rem;
    height: 1rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #bdbdbd;
    text-indent: -9999px;
}
.slick-dots button {
    display: block;
    width: 1rem;
    height: 1rem;
    padding: 0;
    border: none;
    border-radius: 100%;
    background-color: #bdbdbd;
    text-indent: -9999px;
}
.slick-dots li.slick-active button {
    background-color: #000000;
}

.slide {
    position: relative !important;
}

.slick-slide-title {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0;
    right: auto;
    font-size: 1.5em;
    /*top: auto;*/
    bottom: 0;
    width: 100%;
    text-align: left;
}

.slick-slide-title-right {
    color: #fff;
    padding: 20px;
    position: absolute;
    left: auto;
    right: 0;
    font-size: 1.5em;
    top: 0;
    width: 100%;
    text-align: right;
}

.slick-slide-title-shop {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: auto;
    right: auto;
    font-size: 1.5em;
    top: 0;
    width: 100%;
    text-align: center;
    align-content: center;
    align-self: center;
    justify-content: center;
    align-items: center;
    flex: 1;
    flex-direction: row;
}

.slick-slide-label {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: auto;
    right: 0;
    font-size: 1.5em;
    bottom: 0;
    width: 100%;
    text-align: right;
}

.slick-slide-label-shop {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 10%;
    right: auto;
    font-size: 1.5em;
    bottom: 0;
    width: 80%;
    text-align: right;
}

.slick-slide-label-left {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0;
    right: auto;
    font-size: 1.5em;
    bottom: 0;
    width: 80%;
    text-align: left;
}

.slick-slide-news {
    color: #fff;
    padding: 10px;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: left;
}

.slick-track {
    margin-left: 0 !important;
}

.slider-arrows {
    display: block;
    position: absolute;
    top: -45%;
    height: 30px;
    width: 30px;
    transform: translateY(-50%);
}
