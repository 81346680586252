.slick-prev:before, .slick-next:before {
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 35px;
    font-weight: 1000 !important;
    content:'' !important;
}

.slick-prev {
    left: -40px;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: ">" !important;
    font-weight: 700 !important;
}

.text-breadcrumb {
    text-decoration: none !important;
    font-size: 20px !important;
}

.sizeBox {
    /*height: 2.5em;*/
    width: 3.5em;
    margin-left: 5px;
    /*border-radius: 0;*/
    /*outline: none;*/
    /*box-shadow: none;*/
}
.sizeBox:focus {
    border: none;
    outline: none !important;
    box-shadow: none !important;
}

.btn-check:focus {
    border: none;
    outline: none !important;
    box-shadow: none !important;
}

.btn-check:focus + .sizeBox, .sizeBox:focus {
    box-shadow: none !important;
}

@media only screen and (max-width: 600px) {
    .slick-prev, slick-next {
        display: none !important;
    }

    .sizeBox {
        /*height: 2.5em;*/
        width: 3em;
        margin-left: 2em;
        border-radius: 0;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border: none;
    }
}

.w-165{
    width: 165px !important;
}

.w-175{
    width: 175px !important;
}

.w-270{
    width: 270px !important;
}

.fanzine-select {
    border: none;
    outline: none;
    color: #9b9b9b;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}

.fanzine-select:focus {
    border: none;
    outline: none;
    color: #9b9b9b;
    box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
}
