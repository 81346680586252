@media only screen and (max-width: 600px) {

    body {
        padding-top: 70px;
    }

    .nav-bar {
        height: 70px;
    }

    .nav-mega-menu {
        width: auto !important;
        padding-bottom: 1em;
        max-height: 75vh;
        overflow: auto;
    }

    .billboardBanner {
        height: 50px !important;
    }

    .slider-mobile-container {
        display: flex;
        overflow-x: scroll;
        width: 100%;
    }

    .slider-mobile-card {
        flex-shrink: 0;
        width: 164px;
        height: 100%;
        margin-left: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .slider-mobile-news-container {
        display: flex;
        overflow-x: scroll;
        width: 100%;
    }

    .slider-mobile-news-menu {
        flex-shrink: 0;
        width: fit-content;
        height: 100%;
        margin-left: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
    }

    .slider-mobile-news-menu a:active, .slider-mobile-news-menu a:hover, .slider-mobile-news-menu a:focus {
        background-color: transparent !important;
    }

    /* Chrome, Edge, and Safari */
    .slider-mobile-news-container::-webkit-scrollbar {
        width: 0 !important;
        height: 0 !important;
    }

    .slider-mobile-news-container::-webkit-scrollbar-track {
        background: transparent;
    }

    .slider-mobile-news-container::-webkit-scrollbar-thumb {
        background-color: transparent;
        border-radius: 0 !important;
        border: none !important;
    }

    .pushDownBanner {
        height: 90px !important;
        width: 100%;
        background-color: #9c9c9c;
        color: #fff;
    }

    .text-footer {
        color: #686868;
        font-size: 0.875rem !important;
    }

    .text-footer > div {
        font-size: 0.875rem !important;
    }

    .text-news-carousel {
        font-size: 30px !important;
    }

    /*.dropdown-menu {
        width: auto !important;
        right: 0 !important;
        left: auto !important;
        margin-top: 10px !important;
    }*/

    .shop-top-banner {
        display: none !important;
    }

    .slick-slide-title-shop {
        color: #fff;
        padding: 10px;
        position: relative !important;
    }

    .slick-slide-label-shop {
        color: #fff;
        padding: 10px;
        position: absolute;
        top: 50%;
        bottom: auto;
    }

    .slick-slide-label-shop a {
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%) !important;
    }

    .slick-slide-title-right {
        padding: 0 !important;
    }

    .slick-slide-title-right span {
        padding: 10px !important;
    }

    .btn-xl {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        border-radius: 0.3rem;
    }

    .nft-imgComponent {
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100% !important;
        /*height: 900px !important;*/
    }
}
