.form-control{
    background: #f3f3f3 !important;
    border-radius: 0px !important;
}

.card-header
{
    background: white !important;
}
.form-control:focus{
    border-color:transparent!important;
}

#basic-example{
    width: 100% !important;
}


.typeaheadStyles{
    width: 100% !important;
    align-items: center;
    padding: 12px 16px;

    display: flex;
    justify-content: end;

}
