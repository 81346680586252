.nav-tabs {
    border: none  !important;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #495057 !important;
    text-decoration: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    font-weight: 800;
}

.nav-tabs .nav-link.active {
    color: black !important;
    background-color: #fff !important;
    border-color: #ffffff;
    border-bottom: 4px solid  #0e0e0e !important;
}
