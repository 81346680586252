@media only screen and (max-width: 1024px) {

    .nav-content {
        width: 50%
    }

    .podcast-small {
        height: 150px !important;
        width: fit-content;
    }

    .font-nft {
        font-size: 16px !important;
    }

    .font-nft-title {
        font-size: 40px !important;
    }

.nft-imgComponent {
    /*background-repeat: no-repeat;*/
    background-size: contain;
    width: 100% !important;
    height: auto;
}
}
