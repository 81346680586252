.slider-arrow {
    /*position: absolute;*/
    width: 100%;
}
.slider-arrow.left {
    position: absolute;
    margin-left: -100px !important;
    width: 100px !important;
}
.slider-arrow.right {
    position: relative;
    height: 100%;
    margin-left: -2em !important;
}

.arrow-btn {
    height: 120px !important;
    z-index: 1;
}

.next {
    float: right;
}
