.video_card_top_component{
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.video_card_img{
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 199px;
    width: 100%;
}
.video_card_img_club{
    object-fit: cover;
    height: auto;
    width: 134px !important;
}
.video_card_img_overlay{
    height: 199px;
    padding: 0 7px;
}
.video_card_img_overlay_club{
    height: 135px;
    padding: 0 7px;
}
.video_card_play_btn{
    width: 62px;
    height: 62px;
    object-fit: cover;
}
.video_card_bbc_sports{
    width: 60px;
    height: 60px;
    object-fit: cover;
}
.video_card_share_logo{
    width: 16px;
    height: 21.3px;
    object-fit: contain;
}
.video_card_time{
    margin-top: auto;
    flex: 0.7;
}
.up_next_img{
    width: 100%;
    object-fit: contain;
}
.lightGrey{
    color: #878787
}

.lightGreyTwo{
    color: #f3f3f3
}

.purpleOne {
    background-color: #63016a
}

.redOne{
    background-color: #d10214;
}
