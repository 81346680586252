.navbar-card {
    width: 23em;
    height: 220px;
    color: #fff;
    margin: 0 5.5px 7.8px 0.3px;
    padding: 6.7px 16.5px 6.2px 8.3px;
    opacity: 0.74;
    /*-webkit-filter: blur(2.5px);*/
    /*filter: blur(2.5px);*/
    border: solid 1px #fff;
    border-radius: 15px;

    /*background-image: url("../../../assets/images/football-shots-you-need-to-know.jpg");*/
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

.next-match-card {
    height: 153.4px;
    color: #fff;
    padding: 15px 31px 18.4px 20px;
    /*border: solid 1px #fff;*/
    /*border-radius: 15px;*/
    background-size: cover !important;
    /*justify-content: center;*/
}

.h-70 {
    height: 70% !important;
}

.navbar-card .match-date {
    font-size: 12px;
}

.navbar-card .score {
    font-weight: bold;
}

.navbar-card .gameAnalysisButton {
    border: solid #ffffff 0px;
    /*border-radius: 15px;*/
    background-color: rgba(255, 255, 255, 0);
    padding: 5px 20px 5px 20px;
}

.color-layer {
    background-color: rgba(226, 0, 1, 0.9);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    z-index: -1;
}

.navbar-card .pick-your-team-button {
    border: solid #ffffff 1px;
    border-radius: 15px;
    background-color: #007337;
    padding: 5px 50px 5px 50px;
}
