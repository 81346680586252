.slick-center {
    /*-webkit-transform: scale(1.25);*/
    /*-moz-transform: scale(1.25);*/
    /*transform: scale(1.25);*/
    transition-duration: 0.5s;
}
.slick-slide {
    /*padding-right: 30px;*/
    /*padding-left: 30px;*/
    /*-webkit-transform: scale(1.5);*/
    /*-moz-transform: scale(1.5);*/
    /*transform: scale(1.5);*/
    transition-duration: 0.5s;
}

.slick-list {
    -webkit-transform: scale(1.25);
    -moz-transform: scale(1.25);
    transform: scale(1.25);
    transition-duration: 0.5s;
}
