/*gunners*/
.text-gunners {
    color: #e20001 !important;
    fill: #e20001 !important;
}
.font-gunners {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-gunners {
    background-color: #e20001 !important;
}

.bg-gunners-dark {
    background-color: #bc0404 !important;
}

.bg-gunners-hover-color:hover {
    background-color: transparent !important;
    color: #e20001 !important;
}

.bg-gunners-hover-color:hover a {
    background-color: transparent !important;
    color: #e20001 !important;
}

.bg-gunners-before:before {
    color: #e20001 !important;
}

.bg-gunners-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #e20001 !important;
    border-color: #e20001 !important;
}

.bg-gunners-checked input[type="radio"]:checked + label {
    background-color: #e20001;
    border-color: #e20001;
}

.bg-gunners-mobile {
    background-image: url("../../assets/images/target-bg/Gunners.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*cfc-blues*/
.text-cfc-blues {
    color: #203b79 !important;
    fill: #203b79 !important;
}
.font-cfc-blues {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-cfc-blues {
    background-color: #203b79 !important;
}
.bg-cfc-blues-dark {
    background-color: #193370 !important;
}

.bg-cfc-blues-hover-color:hover {
    background-color: transparent !important;
    color: #203b79 !important;
}

.bg-cfc-blues-hover-color:hover a {
    background-color: transparent !important;
    color: #203b79 !important;
}

.bg-cfc-blues-before:before {
    color: #203b79 !important;
}

.bg-cfc-blues-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #203b79 !important;
    border-color: #203b79 !important;
}

.bg-cfc-blues-checked input[type="radio"]:checked + label {
    background-color: #203b79;
    border-color: #203b79;
}

.bg-cfc-blues-mobile {
    background-image: url("../../assets/images/target-bg/CFC-Blues.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*celtic1967*/
.text-celtic1967 {
    color: #00965A !important;
    fill: #00965A !important;
}
.font-celtic1967 {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-celtic1967 {
    background-color: #00965A !important;
}
.bg-celtic1967-dark {
    background-color: #015433 !important;
}

.bg-celtic1967-hover-color:hover {
    background-color: transparent !important;
    color: #00965A !important;
}

.bg-celtic1967-hover-color:hover a {
    background-color: transparent !important;
    color: #00965A !important;
}

.bg-celtic1967-before:before {
    color: #00965A !important;
}

.bg-celtic1967-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #00965A !important;
    border-color: #00965A !important;
}

.bg-celtic1967-checked input[type="radio"]:checked + label {
    background-color: #00965A;
    border-color: #00965A;
}

.bg-celtic1967-mobile {
    background-image: url("../../assets/images/target-bg/Celtic.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*theyellows*/
.text-theyellows {
    color: #FFE800 !important;
    fill: #FFE800 !important;
}
.font-theyellows {
    color: #00A650 !important;
    fill: #00A650 !important;
}
.bg-theyellows {
    background-color: #FFE800 !important;
}
.bg-theyellows-dark {
    background-color: #00A650 !important;
}

.bg-theyellows-hover-color:hover {
    background-color: transparent !important;
    color: #FFE800 !important;
}

.bg-theyellows-hover-color:hover a {
    background-color: transparent !important;
    color: #00A650 !important;
}

.bg-theyellows-before:before {
    color: #00A650 !important;
}

.bg-theyellows-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #00A650 !important;
    border-color: #00A650 !important;
}

.bg-theyellows-checked input[type="radio"]:checked + label {
    background-color: #00A650;
    border-color: #00A650;
}

.bg-theyellows-mobile {
    background-image: url("../../assets/images/target-bg/TheYellows.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*villatillidie*/
.text-villatillidie {
    color: #670E36 !important;
    fill: #670E36 !important;
}
.font-villatillidie {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-villatillidie {
    background-color: #670E36 !important;
}
.bg-villatillidie-dark {
    background-color: #58062B !important;
}

.bg-villatillidie-hover-color:hover {
    background-color: transparent !important;
    color: #670E36 !important;
}

.bg-villatillidie-hover-color:hover a {
    background-color: transparent !important;
    color: #670E36 !important;
}

.bg-villatillidie-before:before {
    color: #670E36 !important;
}

.bg-villatillidie-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #670E36 !important;
    border-color: #670E36 !important;
}

.bg-villatillidie-checked input[type="radio"]:checked + label {
    background-color: #670E36;
    border-color: #670E36;
}

.bg-villatillidie-mobile {
    background-image: url("../../assets/images/target-bg/VillaTillidie.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*coywhites*/
.text-coywhites {
    color: #171716 !important;
    fill: #171716 !important;
}
.font-coywhites {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-coywhites {
    background-color: #171716 !important;
}
.bg-coywhites-dark {
    background-color: #121211 !important;
}

.bg-coywhites-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-coywhites-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-coywhites-before:before {
    color: #171716 !important;
}

.bg-coywhites-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #171716 !important;
    border-color: #171716 !important;
}

.bg-coywhites-checked input[type="radio"]:checked + label {
    background-color: #171716;
    border-color: #171716;
}

.bg-coywhites-mobile {
    background-image: url("../../assets/images/target-bg/COYWhites.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*coyirons*/
.text-coyirons {
    color: #7A263A !important;
    fill: #7A263A !important;
}
.font-coyirons {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-coyirons {
    background-color: #7A263A !important;
}
.bg-coyirons-dark {
    background-color: #71162C !important;
}

.bg-coyirons-hover-color:hover {
    background-color: transparent !important;
    color: #7A263A !important;
}

.bg-coyirons-hover-color:hover a {
    background-color: transparent !important;
    color: #7A263A !important;
}

.bg-coyirons-before:before {
    color: #7A263A !important;
}

.bg-coyirons-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #7A263A !important;
    border-color: #7A263A !important;
}

.bg-coyirons-checked input[type="radio"]:checked + label {
    background-color: #7A263A;
    border-color: #7A263A;
}

.bg-coyirons-mobile {
    background-image: url("../../assets/images/target-bg/COYIrons.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*watp*/
.text-watp {
    color: #034694 !important;
    fill: #034694 !important;
}
.font-watp {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-watp {
    background-color: #034694 !important;
}
.bg-watp-dark {
    background-color: #003A7F !important;
}

.bg-watp-hover-color:hover {
    background-color: transparent !important;
    color: #034694 !important;
}

.bg-watp-hover-color:hover a {
    background-color: transparent !important;
    color: #034694 !important;
}

.bg-watp-before:before {
    color: #034694 !important;
}

.bg-watp-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #034694 !important;
    border-color: #034694 !important;
}

.bg-watp-checked input[type="radio"]:checked + label {
    background-color: #034694;
    border-color: #034694;
}

.bg-watp-mobile {
    background-image: url("../../assets/images/target-bg/WATP.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*lufcmot*/
.text-lufcmot {
    color: #005B9E !important;
    fill: #005B9E !important;
}
.font-lufcmot {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-lufcmot {
    background-color: #005B9E !important;
}
.bg-lufcmot-dark {
    background-color: #004D85 !important;
}

.bg-lufcmot-hover-color:hover {
    background-color: transparent !important;
    color: #005B9E !important;
}

.bg-lufcmot-hover-color:hover a {
    background-color: transparent !important;
    color: #005B9E !important;
}

.bg-lufcmot-before:before {
    color: #005B9E !important;
}

.bg-lufcmot-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #005B9E !important;
    border-color: #005B9E !important;
}

.bg-lufcmot-checked input[type="radio"]:checked + label {
    background-color: #005B9E;
    border-color: #005B9E;
}

.bg-lufcmot-mobile {
    background-image: url("../../assets/images/target-bg/LUFCMOT.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*the-toffees*/
.text-the-toffees {
    color: #0032A1 !important;
    fill: #0032A1 !important;
}
.font-the-toffees {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-the-toffees {
    background-color: #0032A1 !important;
}
.bg-the-toffees-dark {
    background-color: #012A84 !important;
}

.bg-the-toffees-hover-color:hover {
    background-color: transparent !important;
    color: #0032A1 !important;
}

.bg-the-toffees-hover-color:hover a {
    background-color: transparent !important;
    color: #0032A1 !important;
}

.bg-the-toffees-before:before {
    color: #0032A1 !important;
}

.bg-the-toffees-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #0032A1 !important;
    border-color: #0032A1 !important;
}

.bg-the-toffees-checked input[type="radio"]:checked + label {
    background-color: #0032A1;
    border-color: #0032A1;
}

.bg-the-toffees-mobile {
    background-image: url("../../assets/images/target-bg/The-Toffees.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*unitedreds*/
.text-unitedreds {
    color: #D01A0D !important;
    fill: #D01A0D !important;
}
.font-unitedreds {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-unitedreds {
    background-color: #D01A0D !important;
}
.bg-unitedreds-dark {
    background-color: #AE0C00 !important;
}

.bg-unitedreds-hover-color:hover {
    background-color: transparent !important;
    color: #D01A0D !important;
}

.bg-unitedreds-hover-color:hover a {
    background-color: transparent !important;
    color: #D01A0D !important;
}

.bg-unitedreds-before:before {
    color: #D01A0D !important;
}

.bg-unitedreds-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #D01A0D !important;
    border-color: #D01A0D !important;
}

.bg-unitedreds-checked input[type="radio"]:checked + label {
    background-color: #D01A0D;
    border-color: #D01A0D;
}

.bg-unitedreds-mobile {
    background-image: url("../../assets/images/target-bg/UnitedReds.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*thekop*/
.text-thekop {
    color: #BD1423 !important;
    fill: #BD1423 !important;
}
.font-thekop {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-thekop {
    background-color: #BD1423 !important;
}
.bg-thekop-dark {
    background-color: #AA0514 !important;
}

.bg-thekop-hover-color:hover {
    background-color: transparent !important;
    color: #BD1423 !important;
}

.bg-thekop-hover-color:hover a {
    background-color: transparent !important;
    color: #BD1423 !important;
}

.bg-thekop-before:before {
    color: #BD1423 !important;
}

.bg-thekop-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #BD1423 !important;
    border-color: #BD1423 !important;
}

.bg-thekop-checked input[type="radio"]:checked + label {
    background-color: #BD1423;
    border-color: #BD1423;
}

.bg-thekop-mobile {
    background-image: url("../../assets/images/target-bg/TheKop.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*toonarmy*/
.text-toonarmy {
    color: #171716 !important;
    fill: #171716 !important;
}
.font-toonarmy {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-toonarmy {
    background-color: #171716 !important;
}
.bg-toonarmy-dark {
    background-color: #121211 !important;
}

.bg-toonarmy-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-toonarmy-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-toonarmy-before:before {
    color: #171716 !important;
}

.bg-toonarmy-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #171716 !important;
    border-color: #171716 !important;
}

.bg-toonarmy-checked input[type="radio"]:checked + label {
    background-color: #171716;
    border-color: #171716;
}

.bg-toonarmy-mobile {
    background-image: url("../../assets/images/target-bg/ToonArmy.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*coys*/
.text-coys {
    color: #002156 !important;
    fill: #002156 !important;
}
.font-coys {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-coys {
    background-color: #002156 !important;
}
.bg-coys-dark {
    background-color: #001A45 !important;
}

.bg-coys-hover-color:hover {
    background-color: transparent !important;
    color: #002156 !important;
}

.bg-coys-hover-color:hover a {
    background-color: transparent !important;
    color: #002156 !important;
}

.bg-coys-before:before {
    color: #002156 !important;
}

.bg-coys-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #002156 !important;
    border-color: #002156 !important;
}

.bg-coys-checked input[type="radio"]:checked + label {
    background-color: #002156;
    border-color: #002156;
}

.bg-coys-mobile {
    background-image: url("../../assets/images/target-bg/COYS.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*mancityzens*/
.text-mancityzens {
    color: #6CABDD !important;
    fill: #6CABDD !important;
}
.font-mancityzens {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-mancityzens {
    background-color: #6CABDD !important;
}
.bg-mancityzens-dark {
    background-color: #5095CC !important;
}

.bg-mancityzens-hover-color:hover {
    background-color: transparent !important;
    color: #6CABDD !important;
}

.bg-mancityzens-hover-color:hover a {
    background-color: transparent !important;
    color: #6CABDD !important;
}

.bg-mancityzens-before:before {
    color: #6CABDD !important;
}

.bg-mancityzens-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #6CABDD !important;
    border-color: #6CABDD !important;
}

.bg-mancityzens-checked input[type="radio"]:checked + label {
    background-color: #6CABDD;
    border-color: #6CABDD;
}

.bg-mancityzens-mobile {
    background-image: url("../../assets/images/target-bg/Mancityzens.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*comeonleicester*/
.text-comeonleicester {
    color: #00448E !important;
    fill: #00448E !important;
}
.font-comeonleicester {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-comeonleicester {
    background-color: #00448E !important;
}
.bg-comeonleicester-dark {
    background-color: #003B7C !important;
}

.bg-comeonleicester-hover-color:hover {
    background-color: transparent !important;
    color: #00448E !important;
}

.bg-comeonleicester-hover-color:hover a {
    background-color: transparent !important;
    color: #00448E !important;
}

.bg-comeonleicester-before:before {
    color: #00448E !important;
}

.bg-comeonleicester-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #00448E !important;
    border-color: #00448E !important;
}

.bg-comeonleicester-checked input[type="radio"]:checked + label {
    background-color: #00448E;
    border-color: #00448E;
}

.bg-comeonleicester-mobile {
    background-image: url("../../assets/images/target-bg/ComeOnLeicester.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*wearewolves*/
.text-wearewolves {
    color: #FDB913 !important;
    fill: #FDB913 !important;
}
.font-wearewolves {
    color: #000000 !important;
    fill: #000000 !important;
}
.bg-wearewolves {
    background-color: #FDB913 !important;
}
.bg-wearewolves-dark {
    background-color: #000000 !important;
}

.bg-wearewolves-hover-color:hover {
    background-color: transparent !important;
    color: #FDB913 !important;
}

.bg-wearewolves-hover-color:hover a {
    background-color: transparent !important;
    color: #FDB913 !important;
}

.bg-wearewolves-before:before {
    color: #FDB913 !important;
}

.bg-wearewolves-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #FDB913 !important;
    border-color: #FDB913 !important;
}

.bg-wearewolves-checked input[type="radio"]:checked + label {
    background-color: #FDB913;
    border-color: #FDB913;
}

.bg-wearewolves-mobile {
    background-image: url("../../assets/images/target-bg/WeAreWolves.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*saints1885*/
.text-saints1885 {
    color: #D71920 !important;
    fill: #D71920 !important;
}
.font-saints1885 {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-saints1885 {
    background-color: #D71920 !important;
}
.bg-saints1885-dark {
    background-color: #BB0E15 !important;
}

.bg-saints1885-hover-color:hover {
    background-color: transparent !important;
    color: #D71920 !important;
}

.bg-saints1885-hover-color:hover a {
    background-color: transparent !important;
    color: #D71920 !important;
}

.bg-saints1885-before:before {
    color: #D71920 !important;
}

.bg-saints1885-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #D71920 !important;
    border-color: #D71920 !important;
}

.bg-saints1885-checked input[type="radio"]:checked + label {
    background-color: #D71920;
    border-color: #D71920;
}

.bg-saints1885-mobile {
    background-image: url("../../assets/images/target-bg/Saints1885.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*greasychipbutty*/
.text-greasychipbutty {
    color: #EE2737 !important;
    fill: #EE2737 !important;
}
.font-greasychipbutty {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-greasychipbutty {
    background-color: #EE2737 !important;
}
.bg-greasychipbutty-dark {
    background-color: #D61D2C !important;
}

.bg-greasychipbutty-hover-color:hover {
    background-color: transparent !important;
    color: #EE2737 !important;
}

.bg-greasychipbutty-hover-color:hover a {
    background-color: transparent !important;
    color: #EE2737 !important;
}

.bg-greasychipbutty-before:before {
    color: #EE2737 !important;
}

.bg-greasychipbutty-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #EE2737 !important;
    border-color: #EE2737 !important;
}

.bg-greasychipbutty-checked input[type="radio"]:checked + label {
    background-color: #EE2737;
    border-color: #EE2737;
}

.bg-greasychipbutty-mobile {
    background-image: url("../../assets/images/target-bg/GCB.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*palaceeagles*/
.text-palaceeagles {
    color: #1B458F !important;
    fill: #1B458F !important;
}
.font-palaceeagles {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-palaceeagles {
    background-color: #1B458F !important;
}
.bg-palaceeagles-dark {
    background-color: #BF181C !important;
}

.bg-palaceeagles-hover-color:hover {
    background-color: transparent !important;
    color: #1B458F !important;
}

.bg-palaceeagles-hover-color:hover a {
    background-color: transparent !important;
    color: #1B458F !important;
}

.bg-palaceeagles-before:before {
    color: #1B458F !important;
}

.bg-palaceeagles-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #1B458F !important;
    border-color: #1B458F !important;
}

.bg-palaceeagles-checked input[type="radio"]:checked + label {
    background-color: #1B458F;
    border-color: #1B458F;
}

.bg-palaceeagles-mobile {
    background-image: url("../../assets/images/target-bg/PalaceEagles.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*coyhorns*/
.text-coyhorns {
    color: #171716 !important;
    fill: #171716 !important;
}
.font-coyhorns {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-coyhorns {
    background-color: #171716 !important;
}
.bg-coyhorns-dark {
    background-color: #121211 !important;
}

.bg-coyhorns-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-coyhorns-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-coyhorns-before:before {
    color: #171716 !important;
}

.bg-coyhorns-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #171716 !important;
    border-color: #171716 !important;
}

.bg-coyhorns-checked input[type="radio"]:checked + label {
    background-color: #171716;
    border-color: #171716;
}

.bg-coyhorns-mobile {
    background-image: url("../../assets/images/target-bg/COYHorns.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*citybluebirds*/
.text-citybluebirds {
    color: #003B96 !important;
    fill: #003B96 !important;
}
.font-citybluebirds {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-citybluebirds {
    background-color: #003B96 !important;
}
.bg-citybluebirds-dark {
    background-color: #0047B5 !important;
}

.bg-citybluebirds-hover-color:hover {
    background-color: transparent !important;
    color: #003B96 !important;
}

.bg-citybluebirds-hover-color:hover a {
    background-color: transparent !important;
    color: #003B96 !important;
}

.bg-citybluebirds-before:before {
    color: #003B96 !important;
}

.bg-citybluebirds-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #003B96 !important;
    border-color: #003B96 !important;
}

.bg-citybluebirds-checked input[type="radio"]:checked + label {
    background-color: #003B96;
    border-color: #003B96;
}

.bg-citybluebirds-mobile {
    background-image: url("../../assets/images/target-bg/CityBluebirds.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*redandblackarmy*/
.text-redandblackarmy {
    color: #CF0911 !important;
    fill: #CF0911 !important;
}
.font-redandblackarmy {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-redandblackarmy {
    background-color: #CF0911 !important;
}
.bg-redandblackarmy-dark {
    background-color: #B70007 !important;
}

.bg-redandblackarmy-hover-color:hover {
    background-color: transparent !important;
    color: #CF0911 !important;
}

.bg-redandblackarmy-hover-color:hover a {
    background-color: transparent !important;
    color: #CF0911 !important;
}

.bg-redandblackarmy-before:before {
    color: #CF0911 !important;
}

.bg-redandblackarmy-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #CF0911 !important;
    border-color: #CF0911 !important;
}

.bg-redandblackarmy-checked input[type="radio"]:checked + label {
    background-color: #CF0911;
    border-color: #CF0911;
}

.bg-redandblackarmy-mobile {
    background-image: url("../../assets/images/target-bg/Red&BlackArmy.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*the-clarets*/
.text-the-clarets {
    color: #731D31 !important;
    fill: #731D31 !important;
}
.font-the-clarets {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-the-clarets {
    background-color: #731D31 !important;
}
.bg-the-clarets-dark {
    background-color: #691528 !important;
}

.bg-the-clarets-hover-color:hover {
    background-color: transparent !important;
    color: #731D31 !important;
}

.bg-the-clarets-hover-color:hover a {
    background-color: transparent !important;
    color: #731D31 !important;
}

.bg-the-clarets-before:before {
    color: #731D31 !important;
}

.bg-the-clarets-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #731D31 !important;
    border-color: #731D31 !important;
}

.bg-the-clarets-checked input[type="radio"]:checked + label {
    background-color: #731D31;
    border-color: #731D31;
}

.bg-the-clarets-mobile {
    background-image: url("../../assets/images/target-bg/The-Clarets.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*the-terriers*/
.text-the-terriers {
    color: #0E63AD !important;
    fill: #0E63AD !important;
}
.font-the-terriers {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-the-terriers {
    background-color: #0E63AD !important;
}
.bg-the-terriers-dark {
    background-color: #06569C !important;
}

.bg-the-terriers-hover-color:hover {
    background-color: transparent !important;
    color: #0E63AD !important;
}

.bg-the-terriers-hover-color:hover a {
    background-color: transparent !important;
    color: #0E63AD !important;
}

.bg-the-terriers-before:before {
    color: #0E63AD !important;
}

.bg-the-clarets-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #0E63AD !important;
    border-color: #0E63AD !important;
}

.bg-the-clarets-checked input[type="radio"]:checked + label {
    background-color: #0E63AD;
    border-color: #0E63AD;
}

.bg-the-terriers-mobile {
    background-image: url("../../assets/images/target-bg/The-Terriers.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*foresttilidie*/
.text-foresttilidie {
    color: #ED1B2D !important;
    fill: #ED1B2D !important;
}
.font-foresttilidie {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-foresttilidie {
    background-color: #ED1B2D !important;
}
.bg-foresttilidie-dark {
    background-color: #CF1021 !important;
}

.bg-foresttilidie-hover-color:hover {
    background-color: transparent !important;
    color: #ED1B2D !important;
}

.bg-foresttilidie-hover-color:hover a {
    background-color: transparent !important;
    color: #ED1B2D !important;
}

.bg-foresttilidie-before:before {
    color: #ED1B2D !important;
}

.bg-foresttilidie-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #ED1B2D !important;
    border-color: #ED1B2D !important;
}

.bg-foresttilidie-checked input[type="radio"]:checked + label {
    background-color: #ED1B2D;
    border-color: #ED1B2D;
}
.bg-foresttilidie-mobile {
    background-image: url("../../assets/images/target-bg/Forest.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}
/*goonstoke*/
.text-goonstoke {
    color: #ED1B2D !important;
    fill: #ED1B2D !important;
}
.font-goonstoke {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-goonstoke {
    background-color: #ED1B2D !important;
}
.bg-goonstoke-dark {
    background-color: #CF1021 !important;
}

.bg-goonstoke-hover-color:hover {
    background-color: transparent !important;
    color: #ED1B2D !important;
}

.bg-goonstoke-hover-color:hover a {
    background-color: transparent !important;
    color: #ED1B2D !important;
}

.bg-goonstoke-before:before {
    color: #ED1B2D !important;
}

.bg-goonstoke-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #ED1B2D !important;
    border-color: #ED1B2D !important;
}

.bg-goonstoke-checked input[type="radio"]:checked + label {
    background-color: #ED1B2D;
    border-color: #ED1B2D;
}
.bg-goonstoke-mobile {
    background-image: url("../../assets/images/target-bg/GoOnStoke.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*theteessiders*/
.text-theteessiders {
    color: #E30613 !important;
    fill: #E30613 !important;
}
.font-theteessiders {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-theteessiders {
    background-color: #E30613 !important;
}
.bg-theteessiders-dark {
    background-color: #CA020E !important;
}

.bg-theteessiders-hover-color:hover {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-theteessiders-hover-color:hover a {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-theteessiders-before:before {
    color: #E30613 !important;
}

.bg-theteessiders-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #E30613 !important;
    border-color: #E30613 !important;
}

.bg-theteessiders-checked input[type="radio"]:checked + label {
    background-color: #E30613;
    border-color: #E30613;
}

.bg-theteessiders-mobile {
    background-image: url("../../assets/images/target-bg/TheTeesiders.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*roverstilidie*/
.text-roverstilidie {
    color: #004792 !important;
    fill: #004792 !important;
}
.font-roverstilidie {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-roverstilidie {
    background-color: #004792 !important;
}
.bg-roverstilidie-dark {
    background-color: #013F81 !important;
}

.bg-roverstilidie-hover-color:hover {
    background-color: transparent !important;
    color: #004792 !important;
}

.bg-roverstilidie-hover-color:hover a {
    background-color: transparent !important;
    color: #004792 !important;
}

.bg-roverstilidie-before:before {
    color: #004792 !important;
}

.bg-roverstilidie-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #004792 !important;
    border-color: #004792 !important;
}

.bg-roverstilidie-checked input[type="radio"]:checked + label {
    background-color: #004792;
    border-color: #004792;
}

.bg-roverstilidie-mobile {
    background-image: url("../../assets/images/target-bg/RoversTillidie.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*boingboingbaggies*/
.text-boingboingbaggies {
    color: #122E66 !important;
    fill: #122E66 !important;
}
.font-boingboingbaggies {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-boingboingbaggies {
    background-color: #122E66 !important;
}
.bg-boingboingbaggies-dark {
    background-color: #0E2553 !important;
}

.bg-boingboingbaggies-hover-color:hover {
    background-color: transparent !important;
    color: #122E66 !important;
}

.bg-boingboingbaggies-hover-color:hover a {
    background-color: transparent !important;
    color: #122E66 !important;
}

.bg-boingboingbaggies-before:before {
    color: #122E66 !important;
}

.bg-boingboingbaggies-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #122E66 !important;
    border-color: #122E66 !important;
}

.bg-boingboingbaggies-checked input[type="radio"]:checked + label {
    background-color: #122E66;
    border-color: #122E66;
}
.bg-boingboingbaggies-mobile {
    background-image: url("../../assets/images/target-bg/Baggies.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*bcfckro*/
.text-bcfckro {
    color: #1B3F8F !important;
    fill: #1B3F8F !important;
}
.font-bcfckro {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-bcfckro {
    background-color: #1B3F8F !important;
}
.bg-bcfckro-dark {
    background-color: #103381 !important;
}

.bg-bcfckro-hover-color:hover {
    background-color: transparent !important;
    color: #1B3F8F !important;
}

.bg-bcfckro-hover-color:hover a {
    background-color: transparent !important;
    color: #1B3F8F !important;
}

.bg-bcfckro-before:before {
    color: #1B3F8F !important;
}

.bg-bcfckro-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #1B3F8F !important;
    border-color: #1B3F8F !important;
}

.bg-bcfckro-checked input[type="radio"]:checked + label {
    background-color: #1B3F8F;
    border-color: #1B3F8F;
}

.bg-bcfckro-mobile {
    background-image: url("../../assets/images/target-bg/BCFCKro.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*comeonderby*/
.text-comeonderby {
    color: #171716 !important;
    fill: #171716 !important;
}
.font-comeonderby {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-comeonderby {
    background-color: #171716 !important;
}
.bg-comeonderby-dark {
    background-color: #121211 !important;
}

.bg-comeonderby-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-comeonderby-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-comeonderby-before:before {
    color: #171716 !important;
}

.bg-comeonderby-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #171716 !important;
    border-color: #171716 !important;
}

.bg-comeonderby-checked input[type="radio"]:checked + label {
    background-color: #171716;
    border-color: #171716;
}

.bg-comeonderby-mobile {
    background-image: url("../../assets/images/target-bg/ComeOnDerby.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*fanzinesports*/
.text-fanzinesports {
    color: #171716 !important;
    fill: #171716 !important;
}
.font-fanzinesports {
    color: #000000 !important;
    fill: #000000 !important;
}
.bg-fanzinesports {
    background-color: #171716 !important;
}
.bg-fanzinesports-dark {
    background-color: #121211 !important;
}

.bg-fanzinesports-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-fanzinesports-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-fanzinesports-before:before {
    color: #171716 !important;
}

.bg-fanzinesports-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #171716 !important;
    border-color: #171716 !important;
}

.bg-fanzinesports-checked input[type="radio"]:checked + label {
    background-color: #171716;
    border-color: #171716;
}

/*fanzine*/
.text-fanzine {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.font-fanzine {
    color: #74dbef !important;
    fill: #74dbef !important;
}
.bg-fanzine {
    /*background-color: #1e1e1e !important;*/
    background-color: #000000 !important;
}
.bg-fanzine-dark {
    /*old*/
    /*background-color: #24722f !important;*/
    background-color: #74dbef !important;
    color: #000000 !important;
}

.bg-fanzine-hover-color:hover {
    background-color: transparent !important;
    color: #000000 !important;
}

.bg-fanzine-hover-color:hover a {
    background-color: transparent !important;
    color: #000000 !important;
}

.bg-fanzine-before:before {
    color: #74dbef !important;
}

.bg-fanzine-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #74dbef !important;
    border-color: #74dbef !important;
}

.bg-fanzine-checked input[type="radio"]:checked + label {
    background-color: #74dbef;
    border-color: #74dbef;
}

.btn-fanzine {
    background-color: #74dbef !important;
}

/*weareswansea*/
.text-weareswansea {
    color: #171716 !important;
    fill: #171716 !important;
}
.font-weareswansea {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-weareswansea {
    background-color: #171716 !important;
}
.bg-weareswansea-dark {
    background-color: #121211 !important;
}

.bg-weareswansea-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-weareswansea-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-weareswansea-before:before {
    color: #171716 !important;
}

.bg-weareswansea-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #171716 !important;
    border-color: #171716 !important;
}

.bg-weareswansea-checked input[type="radio"]:checked + label {
    background-color: #171716;
    border-color: #171716;
}

.bg-weareswansea-mobile {
    background-image: url("../../assets/images/target-bg/WeAreSwansea.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*oneteaminbristol*/
.text-oneteaminbristol {
    color: #E30613 !important;
    fill: #E30613 !important;
}
.font-oneteaminbristol {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-oneteaminbristol {
    background-color: #E30613 !important;
}
.bg-oneteaminbristol-dark {
    background-color: #CA020E !important;
}

.bg-oneteaminbristol-hover-color:hover {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-oneteaminbristol-hover-color:hover a {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-oneteaminbristol-before:before {
    color: #E30613 !important;
}

.bg-oneteaminbristol-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #E30613 !important;
    border-color: #E30613 !important;
}

.bg-oneteaminbristol-checked input[type="radio"]:checked + label {
    background-color: #E30613;
    border-color: #E30613;
}
.bg-oneteaminbristol-mobile {
    background-image: url("../../assets/images/target-bg/OneTeamInBristol.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*coybees*/
.text-coybees {
    color: #E30613 !important;
    fill: #E30613 !important;
}
.font-coybees {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-coybees {
    background-color: #E30613 !important;
}
.bg-coybees-dark {
    background-color: #CA020E !important;
}

.bg-coybees-hover-color:hover {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-coybees-hover-color:hover a {
    background-color: transparent !important;
    color: #E30613 !important;
}

.bg-coybees-before:before {
    color: #E30613 !important;
}

.bg-coybees-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #E30613 !important;
    border-color: #E30613 !important;
}

.bg-coybees-checked input[type="radio"]:checked + label {
    background-color: #E30613;
    border-color: #E30613;
}

.bg-coybees-mobile {
    background-image: url("../../assets/images/target-bg/COYBees.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*rokerroar*/
.text-rokerroar {
    color: #EB172B !important;
    fill: #EB172B !important;
}
.font-rokerroar {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-rokerroar {
    background-color: #EB172B !important;
}
.bg-rokerroar-dark {
    background-color: #D70F22 !important;
}

.bg-rokerroar-hover-color:hover {
    background-color: transparent !important;
    color: #EB172B !important;
}

.bg-rokerroar-hover-color:hover a {
    background-color: transparent !important;
    color: #EB172B !important;
}

.bg-rokerroar-before:before {
    color: #EB172B !important;
}

.bg-rokerroar-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #EB172B !important;
    border-color: #EB172B !important;
}

.bg-rokerroar-checked input[type="radio"]:checked + label {
    background-color: #EB172B;
    border-color: #EB172B;
}

.bg-rokerroar-mobile {
    background-image: url("../../assets/images/target-bg/RokerRoar.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*weareqpr*/
.text-weareqpr {
    color: #0047B5 !important;
    fill: #0047B5 !important;
}
.font-weareqpr {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-weareqpr {
    background-color: #0047B5 !important;
}
.bg-weareqpr-dark {
    background-color: #003E9E !important;
}

.bg-weareqpr-hover-color:hover {
    background-color: transparent !important;
    color: #0047B5 !important;
}

.bg-weareqpr-hover-color:hover a {
    background-color: transparent !important;
    color: #0047B5 !important;
}

.bg-weareqpr-before:before {
    color: #0047B5 !important;
}

.bg-weareqpr-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #0047B5 !important;
    border-color: #0047B5 !important;
}

.bg-weareqpr-checked input[type="radio"]:checked + label {
    background-color: #0047B5;
    border-color: #0047B5;
}

.bg-weareqpr-mobile {
    background-image: url("../../assets/images/target-bg/WeAreQPR.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*wednesdayites*/
.text-wednesdayites {
    color: #0047B5 !important;
    fill: #0047B5 !important;
}
.font-wednesdayites {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-wednesdayites {
    background-color: #0047B5 !important;
}
.bg-wednesdayites-dark {
    background-color: #003E9E !important;
}

.bg-wednesdayites-hover-color:hover {
    background-color: transparent !important;
    color: #0047B5 !important;
}

.bg-wednesdayites-hover-color:hover a {
    background-color: transparent !important;
    color: #0047B5 !important;
}

.bg-wednesdayites-before:before {
    color: #0047B5 !important;
}

.bg-wednesdayites-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #0047B5 !important;
    border-color: #0047B5 !important;
}

.bg-wednesdayites-checked input[type="radio"]:checked + label {
    background-color: #0047B5;
    border-color: #0047B5;
}
.bg-wednesdayites-mobile {
    background-image: url("../../assets/images/target-bg/Wednesdayites.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*thetractorboys*/
.text-thetractorboys {
    color: #0033A0 !important;
    fill: #0033A0 !important;
}
.font-thetractorboys {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-thetractorboys {
    background-color: #0033A0 !important;
}
.bg-thetractorboys-dark {
    background-color: #002B88 !important;
}

.bg-thetractorboys-hover-color:hover {
    background-color: transparent !important;
    color: #0033A0 !important;
}

.bg-thetractorboys-hover-color:hover a {
    background-color: transparent !important;
    color: #0033A0 !important;
}

.bg-thetractorboys-before:before {
    color: #0033A0 !important;
}

.bg-thetractorboys-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #0033A0 !important;
    border-color: #0033A0 !important;
}

.bg-thetractorboys-checked input[type="radio"]:checked + label {
    background-color: #0033A0;
    border-color: #0033A0;
}

.bg-thetractorboys-mobile {
    background-image: url("../../assets/images/target-bg/TractorBoys.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*playuppompey*/
.text-playuppompey {
    color: #293F9C !important;
    fill: #293F9C !important;
}
.font-playuppompey {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-playuppompey {
    background-color: #293F9C !important;
}
.bg-playuppompey-dark {
    background-color: #1D338F !important;
}

.bg-playuppompey-hover-color:hover {
    background-color: transparent !important;
    color: #293F9C !important;
}

.bg-playuppompey-hover-color:hover a {
    background-color: transparent !important;
    color: #293F9C !important;
}

.bg-playuppompey-before:before {
    color: #293F9C !important;
}

.bg-playuppompey-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #293F9C !important;
    border-color: #293F9C !important;
}

.bg-playuppompey-checked input[type="radio"]:checked + label {
    background-color: #293F9C;
    border-color: #293F9C;
}
.bg-playuppompey-mobile {
    background-image: url("../../assets/images/target-bg/PlayUpPompey.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*bhaseagulls*/
.text-bhaseagulls {
    color: #0057B8 !important;
    fill: #0057B8 !important;
}
.font-bhaseagulls {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-bhaseagulls {
    background-color: #0057B8 !important;
}
.bg-bhaseagulls-dark {
    background-color: #003e84 !important;
}

.bg-bhaseagulls-hover-color:hover {
    background-color: transparent !important;
    color: #0057B8 !important;
}

.bg-bhaseagulls-hover-color:hover a {
    background-color: transparent !important;
    color: #0057B8 !important;
}

.bg-bhaseagulls-before:before {
    color: #0057B8 !important;
}

.bg-bhaseagulls-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #0057B8 !important;
    border-color: #0057B8 !important;
}

.bg-bhaseagulls-checked input[type="radio"]:checked + label {
    background-color: #0057B8;
    border-color: #0057B8;
}
.bg-bhaseagulls-mobile {
    background-image: url("../../assets/images/target-bg/BHAseagulls.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*the-gulls*/
.text-the-gulls {
    color: #0057B8 !important;
    fill: #0057B8 !important;
}
.font-the-gulls {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-the-gulls {
    background-color: #0057B8 !important;
}
.bg-the-gulls-dark {
    background-color: #003e84 !important;
}

.bg-the-gulls-hover-color:hover {
    background-color: transparent !important;
    color: #0057B8 !important;
}

.bg-the-gulls-hover-color:hover a {
    background-color: transparent !important;
    color: #0057B8 !important;
}

.bg-the-gulls-before:before {
    color: #0057B8 !important;
}

.bg-the-gulls-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #0057B8 !important;
    border-color: #0057B8 !important;
}

.bg-the-gulls-checked input[type="radio"]:checked + label {
    background-color: #0057B8;
    border-color: #0057B8;
}
.bg-the-gulls-mobile {
    background-image: url("../../assets/images/target-bg/BHAseagulls.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*cim-bom*/
.text-cim-bom {
    color: #A90432 !important;
    fill: #A90432 !important;
}
.font-cim-bom {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-cim-bom {
    background-color: #A90432 !important;
}
.bg-cim-bom-dark {
    background-color: #FD9800 !important;
}

.bg-cim-bom-hover-color:hover {
    background-color: transparent !important;
    color: #A90432 !important;
}

.bg-cim-bom-hover-color:hover a {
    background-color: transparent !important;
    color: #A90432 !important;
}

.bg-cim-bom-before:before {
    color: #A90432 !important;
}

.bg-cim-bom-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #A90432 !important;
    border-color: #A90432 !important;
}

.bg-cim-bom-checked input[type="radio"]:checked + label {
    background-color: #A90432;
    border-color: #A90432;
}

.bg-cim-bom-mobile {
    background-image: url("../../assets/images/target-bg/GalatasarayAppBackground.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*lablaugrana*/
.text-lablaugrana {
    color: #004D98 !important;
    fill: #004D98 !important;
}
.font-lablaugrana {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-lablaugrana {
    background-color: #004D98 !important;
}
.bg-lablaugrana-dark {
    background-color: #A50044 !important;
}

.bg-lablaugrana-hover-color:hover {
    background-color: transparent !important;
    color: #004D98 !important;
}

.bg-lablaugrana-hover-color:hover a {
    background-color: transparent !important;
    color: #004D98 !important;
}

.bg-lablaugrana-before:before {
    color: #004D98 !important;
}

.bg-lablaugrana-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #004D98 !important;
    border-color: #004D98 !important;
}

.bg-lablaugrana-checked input[type="radio"]:checked + label {
    background-color: #004D98;
    border-color: #004D98;
}

.bg-lablaugrana-mobile {
    background-image: url("../../assets/images/target-bg/BarcelonaBackground.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*barca*/
.text-barca {
    color: #004D98 !important;
    fill: #004D98 !important;
}
.font-barca {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-barca {
    background-color: #004D98 !important;
}
.bg-barca-dark {
    background-color: #A50044 !important;
}

.bg-barca-hover-color:hover {
    background-color: transparent !important;
    color: #004D98 !important;
}

.bg-barca-hover-color:hover a {
    background-color: transparent !important;
    color: #004D98 !important;
}

.bg-barca-before:before {
    color: #004D98 !important;
}

.bg-barca-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #004D98 !important;
    border-color: #004D98 !important;
}

.bg-barca-checked input[type="radio"]:checked + label {
    background-color: #004D98;
    border-color: #004D98;
}

.bg-barca-mobile {
    background-image: url("../../assets/images/target-bg/BarcelonaBackground.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*forza-juve*/
.text-forza-juve {
    color: #171716 !important;
    fill: #171716 !important;
}
.font-forza-juve {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-forza-juve {
    background-color: #171716 !important;
}
.bg-forza-juve-dark {
    background-color: #121211 !important;
}

.bg-forza-juve-hover-color:hover {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-forza-juve-hover-color:hover a {
    background-color: transparent !important;
    color: #171716 !important;
}

.bg-forza-juve-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #182434 !important;
    border-color: #182434 !important;
}

.bg-forza-juve-checked input[type="radio"]:checked + label {
    background-color: #182434;
    border-color: #182434;
}

.bg-forza-juve-mobile {
    background-image: url("../../assets/images/target-bg/Juventus-bg.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*hala-madrid*/
.text-hala-madrid {
    color: #2B3441 !important;
    fill: #2B3441 !important;
}
.font-hala-madrid {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-hala-madrid {
    background-color: #2B3441 !important;
}
.bg-hala-madrid-dark {
    background-color: #232B36 !important;
}

.bg-hala-madrid-hover-color:hover {
    background-color: transparent !important;
    color: #2B3441 !important;
}

.bg-hala-madrid-hover-color:hover a {
    background-color: transparent !important;
    color: #2B3441 !important;
}

.bg-hala-madrid-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #182434 !important;
    border-color: #182434 !important;
}

.bg-hala-madrid-checked input[type="radio"]:checked + label {
    background-color: #182434;
    border-color: #182434;
}

.bg-hala-madrid-mobile {
    background-image: url("../../assets/images/target-bg/RealMadrid.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*die-bayern*/
.text-die-bayern {
    color: #D90B29 !important;
    fill: #D90B29 !important;
}
.font-die-bayern {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-die-bayern {
    background-color: #D90B29 !important;
}
.bg-die-bayern-dark {
    background-color: #C4001D !important;
}

.bg-die-bayern-hover-color:hover {
    background-color: transparent !important;
    color: #D90B29 !important;
}

.bg-die-bayern-hover-color:hover a {
    background-color: transparent !important;
    color: #D90B29 !important;
}

.bg-die-bayern-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #182434 !important;
    border-color: #182434 !important;
}

.bg-die-bayern-checked input[type="radio"]:checked + label {
    background-color: #182434;
    border-color: #182434;
}

.bg-die-bayern-mobile {
    background-image: url("../../assets/images/target-bg/bayern-bg.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*allezparis*/
.text-allezparis {
    color: #182434 !important;
    fill: #182434 !important;
}
.font-allezparis {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-allezparis {
    background-color: #182434 !important;
}
.bg-allezparis-dark {
    background-color: #131E2C !important;
}

.bg-allezparis-hover-color:hover {
    background-color: transparent !important;
    color: #182434 !important;
}

.bg-allezparis-hover-color:hover a {
    background-color: transparent !important;
    color: #182434 !important;
}

.bg-allezparis-before:before {
    color: #182434 !important;
}

.bg-allezparis-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #182434 !important;
    border-color: #182434 !important;
}

.bg-allezparis-checked input[type="radio"]:checked + label {
    background-color: #182434;
    border-color: #182434;
}

.bg-allezparis-mobile {
    background-image: url("../../assets/images/target-bg/psg-bg.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/*wxmafc*/
.text-wxmafc {
    color: #C31018 !important;
    fill: #C31018 !important;
}
.font-wxmafc {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-wxmafc {
    background-color: #C31018 !important;
}
.bg-wxmafc-dark {
    background-color: #000000 !important;
}

.bg-wxmafc-hover-color:hover {
    background-color: transparent !important;
    color: #C31018 !important;
}

.bg-wxmafc-hover-color:hover a {
    background-color: transparent !important;
    color: #C31018 !important;
}

.bg-wxmafc-before:before {
    color: #C31018 !important;
}

.bg-wxmafc-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #C31018 !important;
    border-color: #C31018 !important;
}

.bg-wxmafc-checked input[type="radio"]:checked + label {
    background-color: #C31018;
    border-color: #C31018;
}

.bg-wxmafc-mobile {
    background-image: url("../../assets/images/target-bg/wrexhan.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}

/* theherons */
.text-theherons {
    color: #ff7bac !important;
    fill: #ff7bac !important;
}
.font-theherons {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-theherons {
    background-color: #000000 !important;
}
.bg-theherons-dark {
    background-color: #000000 !important;
}

.bg-theherons-hover-color:hover {
    background-color: transparent !important;
    color: #ff7bac !important;
}

.bg-theherons-hover-color:hover a {
    background-color: transparent !important;
    color: #ff7bac !important;
}

.bg-theherons-before:before {
    color: #ff7bac !important;
}

.bg-theherons-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #ff7bac !important;
    border-color: #ff7bac !important;
}

.bg-theherons-checked input[type="radio"]:checked + label {
    background-color: #ff7bac;
    border-color: #ff7bac;
}

.bg-theherons-mobile {
    background-image: url("../../assets/images/target-bg/WeAreSwansea.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}


/*wsl*/
.text-wsl {
    color: #C31018 !important;
    fill: #C31018 !important;
}
.font-wsl {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-wsl {
    background-color: #C31018 !important;
}
.bg-wsl-dark {
    background-color: #000000 !important;
}

.bg-wsl-hover-color:hover {
    background-color: transparent !important;
    color: #C31018 !important;
}

.bg-wsl-hover-color:hover a {
    background-color: transparent !important;
    color: #C31018 !important;
}

.bg-wsl-before:before {
    color: #C31018 !important;
}

.bg-wsl-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #C31018 !important;
    border-color: #C31018 !important;
}

.bg-wsl-checked input[type="radio"]:checked + label {
    background-color: #C31018;
    border-color: #C31018;
}

/*hatters1885 town*/
.text-hatters1885 {
    color: #f08b1e !important;
    fill: #f08b1e !important;
}
.font-hatters1885 {
    color: #ffffff !important;
    fill: #ffffff !important;
}
.bg-hatters1885 {
    background-color: #f08b1e !important;
}
.bg-hatters1885-dark {
    background-color: #000000 !important;
}

.bg-hatters1885-hover-color:hover {
    background-color: transparent !important;
    color: #f08b1e !important;
}

.bg-hatters1885-hover-color:hover a {
    background-color: transparent !important;
    color: #f08b1e !important;
}

.bg-hatters1885-before:before {
    color: #f08b1e !important;
}

.bg-hatters1885-filters-checked > input:checked {
    outline: none !important;
    box-shadow: none !important;
    background-color: #f08b1e !important;
    border-color: #f08b1e !important;
}

.bg-hatters1885-checked input[type="radio"]:checked + label {
    background-color: #f08b1e;
    border-color: #f08b1e;
}
.bg-hatters1885-mobile {
    background-image: url("../../assets/images/target-bg/hatter1885-bg.png");
    background-position: center;
    /*background-color: #e20001;*/
    justify-content: center;
}
