.custom-shadow {
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
}

.card-image{
    border-radius: 10px;
}

.main-card{
    position: relative;
    background-color: white;
    border-radius: 10px;
}

.black-background-behind-texts{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
}

.card-text{
    font-size: 20px;
    font-weight: bold;
    color: white;
    margin-bottom: -5px;
}

.card-text-number{
    font-size: 30px;
    font-weight: bold;
    color: white;
    margin-bottom: -5px;
}
