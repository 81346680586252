.h-25px
{
    height: 25px !important;
}

.w-25px
{
    width: 25px !important;
}

.h-50px
{
    height: 50px !important;
}

.w-50px
{
    width: 50px !important;
}

.w-10
{
    width: 10% !important;
}
.w-20
{
    width: 20% !important;
}
.w-30
{
    width: 30% !important;
}
.w-40
{
    width: 40% !important;
}
.w-50
{
    width: 50% !important;
}
.w-60
{
    width: 60% !important;
}
.w-70
{
    width: 70% !important;
}
.w-80
{
    width: 80% !important;
}
.w-90
{
    width: 90% !important;
}
.w-100
{
    width: 100% !important;
}
.w-10
{
    width: 10% !important;
}

