.multi-range-slider  {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}
.multi-range-slider {
    display: flex !important;
    position: relative !important;
    border: solid 0 gray !important;
    border-radius: 30px !important;
    padding: 10px 10px !important;
    box-shadow: 0 0 0 rgb(165, 155, 155) !important;
    flex-direction: column !important;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge,*/
}
.multi-range-slider .bar {
    display: flex;
}
.multi-range-slider .bar * {
    transition: all 100ms;
}
.multi-range-slider .bar.active * {
    transition: none;
}
.multi-range-slider .bar-left {
    width: 25%;
    background-color: #E6E6E6!important;
    border-radius: 10px 0 0 10px;
    box-shadow: inset 0 0 5px #E6E6E6;
    padding: 0.5% 0;
}
.multi-range-slider .bar-right {
    /* width: 25% !important; */
    background-color: #E6E6E6 !important;
    border-radius: 0 10px 10px 0 ;
    box-shadow: inset 0 0 5px #E6E6E6;
}
.multi-range-slider .bar-inner {
    background-color: #000000 !important;
    display: flex !important;
    flex-grow: 1 !important;
    flex-shrink: 1 !important;
    height: 5px;
    justify-content: space-between !important;
    position: relative !important;
    border: solid 0 black !important;
    box-shadow: inset 0 0 0 black !important;
}
.multi-range-slider .bar-inner-left {
    width: 50% !important;
}
.multi-range-slider .bar-inner-right {
    width: 50%;
}
.multi-range-slider .thumb {
    background-color: #ffb400 !important;
    position: relative;
    z-index: 1;
    cursor: pointer;
}
.multi-range-slider .thumb::before {
    content: '';
    background-color: white;
    position: absolute;
    width: 20px;
    height: 20px;
    border: solid 1px #ffb400;
    box-shadow: 0 0 3px #ffb400, inset 0 0 5px gray;
    border-radius: 50%;
    z-index: 1;
    /* margin: -6px; */
    cursor: pointer;
}
.multi-range-slider .input-type-range:focus + .thumb::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    width: 11px;
    height: 11px;
    z-index: 2;
    border-radius: 50%;
    border: dotted 1px black;
    box-shadow: 0 0 5px white, inset 0 0 10px black;
}

.multi-range-slider .thumb * {
    position: absolute;
    bottom: 25px;
    width: 30px;
    height: 30px;
    left: -15px;
    font-size: 75%;
    text-align: center;
    line-height: 30px;
    background-color: #000000 !important;
    border-radius: 50%;
    color: white;
    box-shadow: 0 0 5px black;
    display: none;
}
.multi-range-slider .thumb:active * {
    display: block;
}
.input-type-range {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    pointer-events: none;
}

.multi-range-slider .ruler {
    margin: 10px 0 -5px 0;
    display: flex;
    /* display: none; */
}
.multi-range-slider .ruler .ruler-rule {
    border-left: solid 1px;
    border-bottom: solid 1px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 5px 0;
}

.multi-range-slider .ruler .ruler-rule:last-child {
    border-right: solid 1px;
}

.multi-range-slider .label {
    display: flex;
    justify-content: space-between;
    padding: 0;
    margin-top: 10px;
    margin-bottom: -20px;
    /* display: none; */
}
.multi-range-slider .label-min {
    font-size: 80%;
}
.multi-range-slider .label-max {
    font-size: 80%;
}