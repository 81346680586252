@media only screen and (max-width: 787px) {
    .nav-bar {
        height: 70px;
    }

    .nav-content {
        width: 80% !important;
    }

    .small {
        font-size: 12px !important;
    }

    .text-8 {
        font-size: 8px !important;
    }

    .billboardBanner {
        height: 90px !important;
    }

    .podcast-main {
        height: 100% !important;
        width: 100% !important;
    }

    .podcast-small {
        height: 100% !important;
        width: 100% !important;
    }

    .text-footer {
        color: #686868;
        font-size: 0.875rem !important;
    }

    .text-footer > div {
        font-size: 0.875rem !important;
    }

    .shop-top-banner {
        display: none !important;
    }

    .nav-content {
        width: 100% !important;
    }

    .nft-imgComponent {
        background-repeat: no-repeat;
        background-size: cover !important;
        width: 100% !important;
        /*height: 900px !important;*/
    }

    .font-nft {
        font-size: 16px !important;
    }

    .font-nft-title {
        font-size: 40px !important;
    }
}
