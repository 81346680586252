.bg-footer {
    background-color: #1e1e1e;
}

.text-footer {
    color: #686868;
    /*font-size: 1.5rem;*/
}

.text-footer > div {
    /*font-size: 1.5rem;*/
}