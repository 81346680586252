.blackBackground{
    background-image: url("../../assets/images/backgroundOne.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.blackBackgroundTwo{
    background-image: url("../../assets/images/blackBackgroundTwo.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.newAdvertisementBG{
    background-image: url("../../assets/images/newadvertisementblackbg.png");
    background-repeat: no-repeat;
    background-size: cover;
}

.eightyPercent{
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}
.divider {
    display: inline-block;
    border-left: 1px solid #ffffff;
    margin: 0 10px;
    height: 20px;
}
.marginLeft{
    margin-left: 60px;
}
