.navbar-card {
    width: 100% !important;
    height: 220px !important;
    /*margin: 320px 0 15.6px !important;*/
    padding: 15px 29px 18px 20px !important;
    object-fit: contain !important;
    background-image: url("../../../../assets/images/fanzine-new-app-design/upComingMatchBG.png") ;
    background-size: cover !important;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.6);
    background-position: center !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-left: auto !important;
    margin-right: auto !important;
    opacity: 1 !important;
    border: none !important;
}

.next-match-text-style {
    margin: 0 26px 14px 0 !important;
    /*-webkit-filter: blur(10px) !important;*/
    /*filter: blur(10px) !important;*/
    font-family: "Bebas Neue" !important;
    font-size: 28px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.18 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #fff !important;
}

.next-match-date-text-style {
    margin: 31px 5px 3px 6px !important;
    font-family: "SF Pro Display" !important;
    font-size: 11px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.18 !important;
    letter-spacing: -0.12px !important;
    text-align: center !important;
    color: #fff !important;
}

.next-match-home-team-text {
    margin: 14.7px 13.5px 13.3px 10px !important;
    font-family: "SF Pro Display" !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.19 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #fff !important;
}

.next-match-center-team-text {
    margin: 12.1px 4px 13.9px 5px !important;
    font-family: "SF Pro Display" !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.17 !important;
    letter-spacing: -0.2px !important;
    text-align: center !important;
    color: #fff !important;
}

.next-match-away-team-text {
    margin: 14.7px 4px 13.3px 5px !important;
    font-family: "SF Pro Display" !important;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.19 !important;
    letter-spacing: normal !important;
    text-align: left !important;
    color: #fff !important;
}

.next-match-league-text {
    margin: 4px 9px 2px 26px !important;
    font-family: "SF Pro Display" !important;
    font-size: 11px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.18 !important;
    letter-spacing: -0.12px !important;
    text-align: center !important;
    color: #fff !important;
}

.next-match-preview-text {
    margin: 2px 6px 0 38px !important;
    font-family: "SF Pro Display" !important;
    font-size: 12px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 1.17 !important;
    letter-spacing: -0.13px !important;
    text-align: center !important;
    color: #fff !important;
}
