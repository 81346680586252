.billboard {
    margin: 10px auto 10px auto;
    text-align: center;
    width: 100%;
    overflow: hidden;
    min-height: 50px;
}

.leaderboard {
    margin: 10px auto 10px auto;
    text-align: center;
    width: 100%;
    overflow: hidden;
    min-height: 90px;
}

.mpu {
    margin: 0 auto 0 auto;
    text-align: center;
    width: 100%;
    min-height: 50px;
}

.dmpu {
    margin: 0 auto 0 auto;
    text-align: center;
    width: 100%;
    min-height: 600px;
}

.mobile {
    margin: 10px auto 10px auto;
    text-align: center;
    width: 100%;
    overflow: hidden;
    min-height: 50px;
}